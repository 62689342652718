// frontend api
export const API_ROUTES = {
    EVENT: '/data/event',
    LANGUAGES: '/data/languages',
    TTS: '/data/tts',
    VERSION: '/data/version',
    UHF: '/uhf/',
    TOKEN: '/api/token',
    STOP_ROOM: 'stop',
    STOP_PRESET_ROOM: 'stoppreset',
    START_ROOM: 'start',
    JOIN_ROOM: 'join',
    VALIDATE_NICKNAME: 'validate_name',
    UPDATE_LANGUAGE: 'update_language?locale=',
    UPDATE_SPEECH_TOKEN: 'refresh_token'
};

// telemetry client expects a string in the form of <eventType>.<eventName>
// this is to follow the convention of minimizing the # of events we log, per app insights limitations
export const EVENT_KEYS = {
    PAGE: {
        APP_ROUTE_CHANGED: 'PAGE.app_route_changed',
        HOME_PAGE_LOADED: 'PAGE.home_page_loaded',
        HOME_PAGE_PATHNAME: 'PAGE.home_page_pathname',
        CONVERSATION_PAGE_LOADED: 'PAGE.conversation_page_loaded',
        CONTRACTSUMMARY_PAGE_CLICKED: 'PAGE.contractsummary_clicked',
        CONTRACTSUMMARY_PAGE_LOADED: 'PAGE.contractsummary_page_loaded',
        PRIVACY_PAGE_LOADED: 'PAGE.privacy_page_loaded',
        SERVICE_PAGE_LOADED: 'PAGE.service_page_loaded',
        FAQ_PAGE_LOADED: 'PAGE.faq_page_loaded',
        HELP_PAGE_LOADED: 'PAGE.help_page_loaded',
        TPN_PAGE_LOADED: 'PAGE.tpn_page_loaded'
    },
    ERROR: {
        CONVERSATION_TRANSLATOR_UNAVAILABLE: 'ERROR.conversation_translator_unavailable',
        NO_LOCALE_SELECTED: 'ERROR.no_locale_selected',
        INVALID_USERNAME: 'ERROR.invalid_username',
        INVALID_ROOMCODE: 'ERROR.invalid_roomcode',
        NO_SPEECH_SERVICE_INITIALIZED: 'ERROR.no_speech_service_initialized',
        NO_CAPITO_LOCALE_CODE: 'ERROR.no_capito_locale_code',
        TRANSCRIPT_DOWNLOAD_ERROR: 'ERROR.transcript_download_error'
    },
    CONVERSATION: {
        CONVERSATION_JOIN_EXCEPTION: 'CONVERSATION.conversation_join_exception',
        MEETING_JOIN_SUCCESS: 'CONVERSATION.meeting_join_success',
        MEETING_JOIN_FAILURE: 'CONVERSATION.meeting_join_failure',
        TEXT_MESSAGE_SEND_SUCCESS: 'CONVERSATION.text_message_send_success',
        TEXT_MESSAGE_SEND_FAILURE: 'CONVERSATION.text_message_send_failure',
        MEETING_CANCELLED: 'CONVERSATION.meeting_cancelled',
        EXPIRATION_NOTIFICATION: 'CONVERSATION.expiration_notification',
        UPDATED_PARTICIPANTS: 'CONVERSATION.updated_participants',
        SESSION_STARTED: 'CONVERSATION.session_started',
        SESSION_STOPPED: 'CONVERSATION.session_stopped',
        TRANSCRIPT_SAVED: 'CONVERSATION.transcript_saved',
        TEXT_MESSAGE_RECEIVED: 'CONVERSATION.text_message_received',
        START_TRANSCRIBING_SUCCESS: 'CONVERSATION.start_transcribing_success',
        START_TRANSCRIBING_FAILURE: 'CONVERSATION.start_transcribing_failure',
        STOP_TRANSCRIBING_SUCCESS: 'CONVERSATION.stop_transcribing_success',
        STOP_TRANSCRIBING_FAILURE: 'CONVERSATION.stop_transcribing_failure',
        LEAVE_CONVERSATION_SUCCESS: 'CONVERSATION.leave_conversation_success',
        LEAVE_CONVERSATION_FAILURE: 'CONVERSATION.leave_conversation_failure',
        TTS_REQUEST_SUCCESS: 'CONVERSATION.tts_request_success',
        TTS_REQUEST_FAILURE: 'CONVERSATION.tts_request_failure',
        PARTIAL_RECEIVED: 'CONVERSATION.partial_received',
        FINAL_RECEIVED: 'CONVERSATION.final_received'
    }
}

export const CONVERSATION_ERROR_CODES = {
    ROOM_CODE_NOT_FOUND: '400027',
    NICKNAME_ALREADY_TAKEN: '400028',
    DISCONNECTED_CONVERSATION: 'Disconnecting',
    SECURITY_ERROR: 'SecurityError: The operation is insecure'
}

export const DEFAULTS = {
    DEFAULT_LANGUAGE_CODE: 'en',
    DEFAULT_LOCALE_CODE: 'en',
    DEFAULT_REGION_CODE: 'US',
    DEFAULT_CAPITO_LOCALE_CODE: 'en-US'
}

export const CONVERSATION_ICONS = {
    OPACITY: '0.7'
}

export const ENDPOINT = {
    JOIN_NAME: 'join'
}

export const STORAGE = {
    USERNAME: 'username',
    LANGUAGE_CODE: 'languageCode',
    LOCALE_CODE: 'localeCode',
    SPEECH_REGION_CODE: 'speechRegionCode'
}

export const TOOLTIP = {
    BOTTOM: 'bottom',
    TOP: 'top',
    LEFT: 'left',
    RIGHT: 'right'
} as const;

export const VALUES = {
    USERNAME_MIN_LENGTH: 2,
    USERNAME_MAX_LENGTH: 25,
    ROOMCODE_LENGTH: 5
}

export const UNLOCALIZED_STRINGS = {
    PRODUCT_NAME: 'Microsoft Translator',
    CORPORATION_NAME: 'Microsoft Corporation',
    ADDRESS: 'One Microsoft Way, Redmond, WA 98052, U.S.A.',
    COMPANY_NAME: 'Microsoft',
    APPLE_NAME: 'Apple',
    ANDROID_NAME: 'Android',
    AMAZON_NAME: 'Amazon'
}

export const STATIC_CONTENT = {
    TPN_PAGE: '/tpn.html',
    PRIVACY_PAGE: 'https://go.microsoft.com/fwlink/?LinkId=521839',
    SERVICES_PAGE: 'https://aka.ms/translatorserviceagreement',
    CONTRACT_SUMMARY_PAGE: 'https://aka.ms/translatorcontractsummary',
    WEB_FAQ_PAGE: 'https://aka.ms/TranslatorWebFAQ',
    HELP_PAGE: 'https://aka.ms/TranslatorAppHelp',
    TRANSLATOR: 'https://translator.microsoft.com',
    ACCESSIBILITY: 'https://www.microsoft.com/accessibility'
}

export const KEYS = {
    ESCAPE: 27,
    TAB: 9,
    ENTER: 13
}

export function noop(): void { /* intentionally do nothing */ }
